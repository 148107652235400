import Widget from '@components/Widget';
import WidgetBody from '@components/Widget/WidgetBody';
import {Tab} from 'react-bootstrap'

import Form from './Form'
import Page from '@layout/Page'

const StaffAddPatient = ()=>{
  return (

    <>
    <Page title ='Add new patient'>
            <Widget name="UserSettings">
            <Tab.Container defaultActiveKey="patient" transition={true}>

                <WidgetBody>
                <Tab.Content>
                        <Tab.Pane eventKey="patient">
                        <Form type="patient" />
                        </Tab.Pane>
                        <Tab.Pane eventKey="doctor">
                        <Form type="doctor" />
                        </Tab.Pane>
                        </Tab.Content>
                </WidgetBody>
            </Tab.Container>
          </Widget>
    </Page>
    </>
  )
}
export default StaffAddPatient
