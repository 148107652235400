import { Paper,TableBody,TableContainer,Table,TableRow,TableCell } from "@mui/material"

const PatientBasicInfo = ({patient})=>{
  return (
    <TableContainer sx={{width:'100%',marginTop:'5px'}} component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
              <TableCell variant="head">Gender</TableCell>
              <TableCell>{patient.gender==='M'?'Male':'Female'}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant="head">Age</TableCell>
              <TableCell>{patient.age}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant="head">Phone number</TableCell>
              <TableCell>{patient.phoneNumber}</TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
)}

export default PatientBasicInfo
