import {Container,Button, TextField,Box} from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup';
import staffLoginSchema from '@schemas/staffLoginSchema';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import bg1 from '../assets/bg1.jpg';

import { login } from '@services/loginService';
import { isAdmin } from '@services/authService';

import { useForm } from 'react-hook-form'
import { useNavigate, Navigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary:{
      main:'#ff9800'
    },
    secondary:{
      main:'#aaaaaa'
    }
  }
});

const StaffLogin = ()=>{
  const {register, handleSubmit, reset, formState:{errors}} = useForm({resolver:yupResolver(staffLoginSchema)})
  const navigate = useNavigate();

  const submitHandler = async (formVals)=>{
    await login(formVals,'staff');
    reset()
    navigate('/admin')
    window.location.reload(true)
  }
  if(isAdmin()){
    return <Navigate to="/admin" />
  }
  return(
    <>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        backgroundImage:`linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${bg1})`,
      }}>
      <Container sx={{backgroundColor:'#39f',padding:'2em' ,my:10,width:{xs:3/4,sm:2/4,md:1.5/4,lg:1/4},height:'min-content'}}>
          <Box sx={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
            <div>
              <img src='http://172.105.11.248/uploads/hospital_content/logo/1.png?1682933755' alt='logo' height='30px'></img>
            </div>
            <div>
              <FontAwesomeIcon icon ={faKey} size='2x'></FontAwesomeIcon>
            </div>
          </Box>
          <hr/>
        <form autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
          <Box sx={{my:4}}>
            <TextField sx={{backgroundColor:'white'}} {...register('email')} fullWidth label='Email'  type='email'  variant='filled' required></TextField>
            <p style={{color:'#ff3333'}}> {errors.email?.message} </p>
          </Box>

          <Box sx={{my:4}}>
            <TextField sx={{backgroundColor:'white'}} {...register('password')} fullWidth label= 'Password' type='password' variant='filled' required></TextField>
            <p style={{color:'#ff3333'}}>{errors.password?.message}</p>
          </Box>

            <ThemeProvider theme={theme}>
            <Button sx={{my:3,color:'white'}} color ='primary' variant='contained' type="submit" fullWidth>Sign in</Button>
            </ThemeProvider>
            <div style={{color:'#b0de37',marginBottom:'30px'}}>
              Forgot password?
            </div>
        </form>
        </Container>
    </div>
    </>
  )
}

export default StaffLogin;
