import LabInvestigationTable from "./LabInvestigationTable"

const LabInvestigation = ({patient})=>{
  const labInvestigations = null
  return (
    <>
    <h1>
      Lab investigation
    </h1>
    <LabInvestigationTable/>
    <div style={{display:'flex', justifyContent:'space-around', marginTop:'5px'}}>
    {!labInvestigations?'No record found':''}
    </div>
    </>
  )
}
export default LabInvestigation
export {LabInvestigationTable}
