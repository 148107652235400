import tick from '@assets/tick.png'
// styling
import styled from 'styled-components/macro';
import {flex} from '@styles/vars';

// components
import {Container} from '@components/Widget/style';
import {motion} from 'framer-motion';
import Page from '@layout/Page';

const FlexContainer = styled(Container)`
  justify-content: center;
  padding: 24px;
`;

const Content = styled.div`
  ${flex.col};
  gap: 24px;
  align-items: center;
  text-align: center;

  button {
    max-width: 240px;
  }
`;


const SuccessPage = ()=>{
  return(
    <Page>
      <FlexContainer as={motion.div}
                     initial={{opacity: 0}}
                     whileInView={{opacity: 1}}
                     transition={{duration: .4}}
                     viewport={{once: true}}>
          <Content>
              <img alt={'tick'} src={tick} style={{width:'300px', height:'300px', objectFit:'resize'}}/>
              <h2>Success</h2>

          </Content>
      </FlexContainer>
    </Page>
  )
}
export default SuccessPage
