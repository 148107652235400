import Page from '@layout/Page';
import PatientsList from '@widgets/PatientsList';

const Patients = () => {
    return (
        <Page title="Patients List">
            <PatientsList/>
        </Page>
    );
}

export default Patients;
