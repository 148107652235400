import { Table,TableContainer,TableBody,TableRow,TableCell,TableHead,Paper } from "@mui/material";

const LabInvestigationTable = ({labInvestigations})=>{
  return(
    <>
    <TableContainer component={Paper} sx={{marginTop:'20px'}}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Test Name</TableCell>
          <TableCell>Case id</TableCell>
          <TableCell>Lab</TableCell>
          <TableCell>Sample Collected</TableCell>
          <TableCell>Expected date</TableCell>
          <TableCell>Approved by</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {labInvestigations?.map((investigation)=>{
          return (
            <TableRow>
              <TableCell>{investigation.testName}</TableCell>
              <TableCell>{investigation.caseId}</TableCell>
              <TableCell>{investigation.lab}</TableCell>
              <TableCell>{investigation.sampleCollected}</TableCell>
              <TableCell>{investigation.expectedDate}</TableCell>
              <TableCell>{investigation.approvedBy}</TableCell>
              <TableCell>{investigation.action}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </TableContainer>
    </>
  )
}
export default LabInvestigationTable
