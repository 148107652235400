import { Table,TableContainer,TableBody,TableRow,TableCell,TableHead,Paper } from "@mui/material";

const TreatmentHistoryTable = ({treatmentHistory})=>{
  return(
    <>
    <TableContainer component={Paper} sx={{marginTop:'20px'}}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>OPD No</TableCell>
          <TableCell>Case ID</TableCell>
          <TableCell>Appointment Date</TableCell>
          <TableCell>Symptoms</TableCell>
          <TableCell>Consultant</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          treatmentHistory.map((treatment)=>{
            return(
              <TableRow>
                <TableCell>{treatment.opdNo}</TableCell>
                <TableCell>{treatment.caseId}</TableCell>
                <TableCell>{treatment.appointmentDate}</TableCell>
                <TableCell>{treatment.symptoms}</TableCell>
                <TableCell>{treatment.constulant}</TableCell>
                <TableCell>{treatment.action}</TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  </TableContainer>
    </>
  )
}
export default TreatmentHistoryTable
