import {Button, TextField,Container,Box} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup';
import { faKey} from '@fortawesome/free-solid-svg-icons'
import patientLoginSchema from '@schemas/patinetLoginSchema';
import bg1 from '../../assets/bg1.jpg';

import { login } from '@services/loginService';

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';

const PatientLogin = ()=>{
  const {register, handleSubmit,reset, formState:{errors}} = useForm({resolver:yupResolver(patientLoginSchema)})
  const navigate = useNavigate();

  const submitHandler = (formVals)=>{
    login(formVals,'patient');
    reset();
    navigate('/patient')
  }

  return(
    <>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundImage:`linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${bg1})`,
        height:'100%',
      }}>
        <Container sx={{backgroundColor:'black',padding:'2em' ,my:10,width:{xs:3/4,sm:2/4,md:1.5/4,lg:1/4},height:'min-content'}}>

        <Box sx={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
            <div>
              <img src='http://172.105.11.248/uploads/hospital_content/logo/1.png?1682933755' alt='logo' height='30px'></img>
            </div>
            <div>
              <FontAwesomeIcon icon ={faKey} size='2x'></FontAwesomeIcon>
            </div>
          </Box>
          <hr/>
        <form autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
            <Box sx={{my:4}}>
            <TextField sx={{backgroundColor:'white'}} {...register('phoneNumber')} fullWidth label='Phone Number' variant='filled' required></TextField>
            <p style={{color:'#ff9494', fontSize:'0.8em'}}>{errors.phoneNumber?.message}</p>
            </Box>

            <Box sx={{my:4}}>
            <TextField sx={{backgroundColor:'white'}} {...register('password')}fullWidth label= 'Password' type='password' required variant='filled'></TextField>
            <p style={{color:'##ff9494'}}> {errors.password?.message} </p>
            </Box>
            <Button sx={{my:3}}variant='contained' type="submit" fullWidth>Sign in</Button>
            <div style={{color:'white',marginBottom:'30px'}}>
              Forgot password?
            </div>
        </form>
        </Container>
    </div>
    </>
  )
}

export default PatientLogin;
