import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_API_URL;

const token = localStorage.getItem("token");

export const getPatient = async (id) => {
  const response = await axios.get(`${baseUrl}patient/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
