import "./App.css";
import "./fonts/icomoon/style.css";
import "./components/PatientLogin";
import PatientLogin from "./components/PatientLogin";
import StaffLogin from "./components/StaffLogin";
import BasicLayout from "./components/BasicLayout";
import StaffAddPatient from "@components/StaffAddPatient";
import PageNotFound from "@components/PageNotFound";
import Patients from "@components/Patients";
import SuccessPage from "@components/SuccessPage";

import { SidebarContextAPI } from "@contexts/sidebarContext";
import { useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { ThemeProvider, StyleSheetManager } from "styled-components";

import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";

import { useInterfaceContext } from "@contexts/interfaceContext";
import { isAdmin, isPatient } from "@services/authService";
import rtlPlugin from "stylis-plugin-rtl";
import DashboardH from "@components/DashboardH";
import DashboardA from "@components/DashboardA";
import Patient from "@components/Patient";

function App() {
  const { isDarkMode, direction } = useInterfaceContext();
  const theme = createTheme({
    direction: direction,
  });
  const page = document.documentElement;
  useEffect(() => {
    page.setAttribute("dir", direction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  return (
    <div className='App'>
      <StyleSheetManager stylisPlugins={direction === "rtl" ? [rtlPlugin] : []}>
        <ThemeProvider theme={{ theme: isDarkMode ? "dark" : "light" }}>
          <SidebarContextAPI>
            <Router>
              <Routes>
                <Route
                  path='/'
                  element={<Navigate to='/staff-login' replace />}
                />
                <Route path='/patient-login' element={<PatientLogin />} />
                <Route path='/staff-login' element={<StaffLogin />} />
                <Route
                  path='/admin'
                  element={
                    isAdmin() ? <BasicLayout /> : <Navigate to='/staff-login' />
                  }
                >
                  <Route path='/admin' element={<DashboardH />} />
                  <Route path='add-patient' element={<StaffAddPatient />} />
                  <Route
                    path='/admin/add-patient/success'
                    element={<SuccessPage />}
                  />
                  <Route path='patients' element={<Patients />} />
                  <Route path='patient/:id' element={<Patient />} />
                </Route>
                <Route
                  path='/patient'
                  element={
                    isPatient() ? (
                      <BasicLayout />
                    ) : (
                      <Navigate to='/patient-login' />
                    )
                  }
                >
                  <Route path='' element={<DashboardA />} />
                </Route>
                <Route path='*' element={<PageNotFound />} />
              </Routes>
            </Router>
          </SidebarContextAPI>
        </ThemeProvider>
      </StyleSheetManager>
    </div>
  );
}

export default App;
