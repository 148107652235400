export const menu = [
  {
    cat: "dashboard",
    icon: "blocks",
    links: [
      { name: "Dashboard A", link: "/dashboard_a" },
      { name: "Dashboard B", link: "/dashboard_b" },
      { name: "Dashboard C", link: "/dashboard_c" },
      { name: "Dashboard D", link: "/dashboard_d" },
      { name: "Dashboard E", link: "/dashboard_e" },
      { name: "Dashboard F", link: "/dashboard_f" },
      { name: "Dashboard G", link: "/dashboard_g" },
      { name: "Dashboard H", link: "/dashboard_h" },
      { name: "Dashboard I", link: "/dashboard_i" },
      { name: "Dashboard J", link: "/dashboard_j" },
      { name: "Dashboard K", link: "/dashboard_k" },
      { name: "Page 404", link: "/404" },
    ],
  },
  {
    cat: "appointments",
    icon: "calendar",
    links: [
      { name: "Patient Appointments", link: "/patient_appointments" },
      { name: "Doctor Appointments", link: "/doctor_appointments" },
    ],
  },
  {
    cat: "patients",
    icon: "users",
    links: [
      { name: "Patients", link: "/admin/patients", adminOnly: true },
      { name: "Add patient", link: "/admin/add-patient", adminOnly: true },
      { name: "Tests", link: "/tests" },
    ],
  },
  {
    cat: "doctors",
    icon: "stethoscope",
    links: [
      { name: "Doctors", link: "/doctors" },
      { name: "Staff", link: "/staff" },
    ],
  },
  {
    cat: "messages",
    icon: "comment",
    links: [
      { name: "Doctor Messenger", link: "/doctor_messenger" },
      { name: "Patient Messenger", link: "/patient_messenger" },
    ],
  },
  {
    cat: "reviews",
    icon: "star",
    links: [
      { name: "Doctor Reviews", link: "/doctor_reviews" },
      { name: "Patient Reviews", link: "/patient_reviews" },
    ],
  },
  {
    icon: "wallet",
    name: "Finances",
    link: "/finances",
  },
  // {
  //   name: "Add patient",
  //   link: "/admin/add-patient",
  //   adminOnly: true,
  // },
  {
    icon: "settings",
    name: "Settings",
    link: "/settings",
  },
];
