import * as yup from "yup";
import axios from "axios";
const token = localStorage.getItem("token");
const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_API_URL;

const addPatientSchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(/[A-Z].*/, "Name should start with a capital error"),
  gender: yup
    .string()
    .required()
    .oneOf(["M", "F"], "Please choose a valid option"),
  phoneNumber: yup
    .string()
    .required()
    .matches(/^\d{11}$/, "Please enter a valid phone number")
    .test(
      "Unique Phone Number",
      "Phone Number belongs to another user",
      async function (value) {
        const response = await axios.post(
          `${baseUrl}validation/unique`,
          { fieldName: "phoneNumber", val: value },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        if (response.data.msg) {
          return false;
        } else {
          return true;
        }
      }
    ),
  age: yup
    .number()
    .typeError("Please enter valid age")
    .integer("Please enter valid age")
    .min("1")
    .required(),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      "Please enter a valid email address"
    )
    .test(
      "Unique  email",
      "Email belongs to another user",
      async function (value) {
        const response = await axios.post(
          `${baseUrl}validation/unique`,
          { fieldName: "email", val: value },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        if (response.data.msg) {
          return false;
        } else {
          return true;
        }
      }
    ),
  consent: yup.string().required().matches("yes", "Please agree to continue"),
  height: yup
    .number()
    .typeError("Please enter a number")
    .integer("Please enter valid height")
    .min(1, "Please enter a valid height"),
  weight: yup
    .number()
    .typeError("Please enter a number")
    .integer("Please enter valid weight")
    .min(1, "Please enter a valid weight"),
  shoulder: yup
    .number()
    .typeError("Please enter a number")
    .integer("Please enter valid value")
    .min(1, "Please enter a valid value"),
  abs: yup
    .number()
    .typeError("Please enter a number")
    .integer("Please enter valid value")
    .min(1, "Please enter a valid value"),
  thigh: yup
    .number()
    .typeError("Please enter a number")
    .integer("Please enter valid value")
    .min(1, "Please enter a valid value"),
  arms: yup
    .number()
    .typeError("Please enter a number")
    .integer("Please enter valid value")
    .min(1, "Please enter a valid value"),
  waist: yup
    .number()
    .typeError("Please enter a number")
    .integer("Please enter valid value")
    .min(1, "Please enter a valid value"),
  maritalStatus: yup
    .string()
    .oneOf(
      ["married", "single", "divorced", "widowed"],
      "Please select valid option"
    ),
  city: yup.string(),
  address: yup.string(),
  contact: yup.string(),
  job: yup.string(),
  breakfast: yup.string(),
  lunch: yup.string(),
  dinner: yup.string(),
  snacks: yup.string(),
  favFoodGrps: yup.array(),
  favVegetables: yup.array(),
  fastFoodPreference: yup.string(),
  allergies: yup.string(),
  hatedFoods: yup.string(),
  insulin: yup.number().min(0),
  conditions: yup.array(),
  symptoms: yup.array(),
  sleep: yup.number().typeError("Please enter a number"),
  excuses: yup.string(),
  bloodTest: yup.string(),
  optionalBloodTest: yup.string(),
  currentActivityLevel: yup.string(),
  workoutPlace: yup.string(),
  workoutEquipment: yup.string(),
  workoutDays: yup.string(),
  workoutHours: yup.string(),
  otherWorkoutEquipment: yup.string(),
  workoutExperience: yup.string(),
  exerciseLoveOrHate: yup.string(),
  exercisePain: yup.string(),
  maxBench: yup.number().typeError("Please enter a number"),
  maxDeadLift: yup.number().typeError("Please enter a number"),
  maxSquat: yup.number().typeError("Please enter a number"),
  howHeard: yup.string(),
  extraInfo: yup.string(),
});

export default addPatientSchema;
