import { isExpired, decodeToken } from "react-jwt";
export const requireAuth = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const isAdmin = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  const decodedToken = decodeToken(token);
  const isTokenExpired = isExpired(token);
  if (isTokenExpired) {
    return false;
  }
  if (decodedToken.type === "staff") {
    return true;
  }
};
export const isPatient = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  const decodedToken = decodeToken(token);
  const isTokenExpired = isExpired(token);
  if (isTokenExpired) {
    return false;
  }
  if (decodedToken.type === "patient") {
    return true;
  }
};
