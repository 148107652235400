import * as yup from "yup";
const patientLoginSchema = yup
  .object({
    phoneNumber: yup
      .string()
      .matches(/^\d{11}$/, { message: "* Invalid phone number" })
      .required(),
    password: yup.string().required(),
  })
  .required();

export default patientLoginSchema;
