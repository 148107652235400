import { Container } from "../style"
import LabeledFormInput from "@ui/LabeledFormInput"
import CustomSelect from '@ui/Select';
import { isArray } from "lodash";


const FormFragment = ({formFieldData, formData, setFormData, errors , register,t})=>{
  const onChangeHandler = (e,newKey)=>{
    e.preventDefault();
    const newFormData = {...formData}
    let newVal = e.target.value
    if (newKey ==='name'){
      newVal = newVal.charAt(0).toUpperCase() + newVal.slice(1);
    }
    newFormData[newKey] = newVal
    setFormData(newFormData)
  }

  const onSelectChangeHandler = (e ,newKey)=>{
    const newFormData = {...formData}
    if(isArray(e)){
      let allVals = []
      e.forEach(selectObj=>{
        allVals.push(selectObj.value)
      })
      newFormData[newKey] = allVals
    }
    else{
      newFormData[newKey] = e.value
    }
    setFormData(newFormData)
  }

  return(

    <div className="wrapper">
    <div className='form-header' style={{marginBottom:'2em'}}></div>
      <Container>

      <h1> {t(formFieldData.title)}</h1>
      </Container>

      <Container style={{marginLeft:'2em'}}>
      {
        formFieldData.fields.map((field, index)=>
        {
          register(...field.fieldName)
          const newFormData = {...formData}
          newFormData[field.fieldTitle] ='';
          if(field.type==='select'){
            return (
            <div key={field.fieldName}>

            <LabeledFormInput label={t(field.fieldTitle)} placeholder={"Select value" || field.placeholder} id={field.fieldName} title={t(field.fieldTitle)}  customInput={
              <CustomSelect  variant="basic" name={field.fieldName} options={field.options} isMulti={field.isMulti} changeHandler={e=>onSelectChangeHandler(e, field.fieldName)}/>}
              />
              <p style={{fontSize:'0.6em' , color:'red', marginLeft:'1em'}}>{errors[field.fieldName]?.message}</p>
            </div>)

          }
          return(
            <div key={field.fieldName}>
            <LabeledFormInput id={field.fieldName}  label = {t(field.fieldTitle)} title={t(field.fieldTitle)} type={field.type} placeholder={t(field.placeholder)||t("Enter value")}
                 value={formData[field.fieldName]} onChange = {e=>onChangeHandler(e,field.fieldName)} name={field.fieldName}/>
              <p style={{fontSize:'0.6em' , color:'red', marginLeft:'1em'}}>{errors[field.fieldName]?.message}</p>
            </div>
          )}
          )
        }
      </Container>

  </div>
    )

}
export default FormFragment
