import PatientBasicInfo from "./PatientBasicInfo"
import PatientFindings from "@components/PatientFindings"
import TimelineChart from "@components/Timeline/TimelineChart";
import { Grid } from "@mui/material";
import {PatientVisitTable} from '@components/PatientVisits'
import { TreatmentHistoryTable } from "@components/TreatmentHistory";
import {LabInvestigationTable } from '@components/LabInvestigation'

const PatientOveriew =({patient})=>{
  const patientFormData = patient?.form_data?.[0]?.data;
  const visits = patient?.visits;
  const labInvestigations = patient?.labInvestigations
  const treatmentHistory = patient?.treatmentHistory
  const data = patient?.timeline

  return(
    <>
    <Grid container>
    <Grid item xs={6} style={{border:'1px solid #ddd'}}>
      <div style={{padding:'5px', border:'1px solid #ddd'}}>
      <h2>{patient.name}</h2>
      </div>
      <PatientBasicInfo patient={patient}/>
      <div style={{marginTop:'20px'}}>
        <PatientFindings patientFormData={patientFormData}/>
      </div>
      <div style ={{marginTop:'20px', padding:'20px',border:'1px solid #ddd'}}>
        <b>Consultant doctor</b>
      </div>

      <div style={{marginTop:'20px', padding:'20px',border:'1px solid #ddd'}}>
        <b>TimeLine</b>
      </div>
    </Grid>

    <Grid item xs={6} style={{border:'1px solid #ddd'}}>
    <div style={{padding:'5px', border:'1px solid #ddd'}}>
      <h2>Medical history</h2>
      </div>
      <div style={{border:'1px solid #ddd',margin:'5px'}}>
        <TimelineChart data={data} />
      </div>
      <div style={{margin:'5px',border:'1px solid #ddd'}}>
        <h2>Visits</h2>
        <PatientVisitTable visits={visits}/>
      </div>
      <div style={{margin:'5px',border:'1px solid #ddd'}}>
        <h2>Lab Investigations</h2>
        <LabInvestigationTable labInvestigations={labInvestigations}/>
      </div>
      <div style={{margin:'5px',border:'1px solid #ddd'}}>
        <h2>Treatment History</h2>
        <TreatmentHistoryTable treatmentHistory={treatmentHistory}/>
      </div>
    </Grid>
    </Grid>
    </>
  )

}
export default PatientOveriew
