import { Table,TableContainer,TableBody,TableRow,TableCell,TableHead,Paper } from "@mui/material";

const PatientVisitTable = ({visits})=>{

  return(
    <>
    <TableContainer component={Paper} sx={{marginTop:'20px'}}>
  <Table>
    <TableHead>
      <TableRow>
      <TableCell>OPD No</TableCell>
      <TableCell>Case id</TableCell>
      <TableCell>Appointment date</TableCell>
      <TableCell>Consultant</TableCell>
      <TableCell>Reference</TableCell>
      <TableCell>Symptoms</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {visits?.map(visit=>{
        return(
          <TableRow id={visit.id}>
            <TableCell>{visit.id}</TableCell>
            <TableCell>{visit.time}</TableCell>
            <TableCell>{visit.patient}</TableCell>
            <TableCell>{visit.doctor}</TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  </Table>
</TableContainer>
    </>
)}
export default PatientVisitTable;
