import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_API_URL;

const getFormMetaData = async () => {
  const response = await axios.get(`${baseUrl}form/?name=add-patient`);
  return response.data;
};
export default getFormMetaData;
