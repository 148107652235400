import PatientVisit from "@components/PatientVisits";
import PatientOveriew from "@components/PatientOverview";
import LabInvestigation from "@components/LabInvestigation";
import TreatmentHistory from "@components/TreatmentHistory";
import TimeLine from "@components/Timeline";

const PatientData = ({displayInfo,patient})=>{
  if(displayInfo.value === 'overview'){
    return(
      <PatientOveriew patient={patient}/>
    )
  }
  else if(displayInfo.value==='visit'){
    return(
      <div>
        <PatientVisit patient={patient}/>
      </div>
    )
  }
  else if(displayInfo.value === 'lab-investigation'){
    return(

      <LabInvestigation patient={patient}/>
    )
  }
  else if(displayInfo.value==='treatment-history'){
    return(
      <TreatmentHistory patient={patient}/>
    )
  }
  else if(displayInfo.value==='timeline'){
    return(
      <TimeLine patient={patient}/>
    )
  }

}
export default PatientData
