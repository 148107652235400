// styled components
import {Container, Item} from '@ui/TabNav/style';
import {Button} from './style'

// utils
import {nanoid} from "nanoid";

const NavButton = ({state, filter, handler}) => {
    const isActive = state.value === filter.value;
    return (
        <Button className={isActive ? 'active' : ''}
                onClick={() => handler({value: filter.value, label: filter.label})}
        >
            {filter.label}
        </Button>
    )
}

const InfoNav = ({state, handler}) => {
    const options = [
        {
            value: 'overview',
            label: 'Overview'
        },
        {
            value: 'visit',
            label: 'Visits'
        },
        {
            value: 'lab-investigation',
            label: 'Lab investigation'
        },
        {
          value: 'treatment-history',
          label: 'Treatment history'
      },
      {
        value:'timeline',
        label:'Timeline'
      },
    ];

    return (
        <Container as="ul" className="info">
            {
                options.map(item => {
                    return (
                        <Item key={nanoid(3)}>
                            <NavButton state={state} filter={item} handler={handler} />
                        </Item>
                    )
                })
            }
        </Container>
    )
}

export default InfoNav;
