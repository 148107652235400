import axios from "axios";
const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_API_URL;

const login = async (formVals, loginType) => {
  const response = await axios.post(`${baseUrl}${loginType}/login`, {
    ...formVals,
  });
  const { token } = response.data;
  localStorage.setItem("token", token);
};
export { login };
