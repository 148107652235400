import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Legend, Tooltip } from 'recharts';

const TimelineChart = (data)=>{
  if (!data)
  {
    return(
      <div style={{display:'flex', justifyContent:'space-around', marginTop:'5px'}}>
        No data present
    </div>
    )
  }

return (
  <>
  <ResponsiveContainer width='100%' aspect={3}>
  <LineChart width={730} height={250} data={data}
  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>

  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="name" />
  <YAxis />
  <Tooltip />
  <Legend />
  <Line type="monotone" dataKey="pv" stroke="#8884d8" />
  <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
</LineChart>
  </ResponsiveContainer>
  </>
  )
}
export default TimelineChart
