import Sidebar from '@layout/Sidebar';
import Panel from '@layout/Panel'
import GlobalStyles from '@styles/global';

import { Outlet } from 'react-router-dom';
const BasicLayout = ()=>{

  return(
    <>
    <div className='app'>
      <div className='app_content'>
      <Sidebar/>
      <GlobalStyles/>

      <Panel/>
        <Outlet/>

      </div>
    </div>
    </>
    )
}
export default BasicLayout;
