import PatientVisitTable from "./Table";
const PatientVisit = ({patient})=>{
  const visits = patient?.visits
  return (
    <>
    <h1>
      Visits
    </h1>
    <PatientVisitTable visits = {visits}/>
    <div style={{display:'flex', justifyContent:'space-around', marginTop:'5px'}}>

    {!visits?'No record found':''}
    </div>
    </>
  )

}

export default PatientVisit;
export {PatientVisitTable}
