

const PatientFindings = ({patientFormData})=>{
  return(
    <>
    <div style={{marginTop:'5px'}}>
              <div style={{padding:'5px',paddingLeft:'20px', marginTop:'10px', border:'1px solid #ddd'}}>
              <b>Known allergies:</b>
              </div>

              <ul>
              {patientFormData?.allergies?.map((allergy,index)=>{
                return (
                  <li key = {index} style={{padding:'5px 20px',margin:'5px',border:'1px solid #ddd'}}>
                  {'\u2022'} {allergy}
                </li>
                )
              })}
              </ul>
              <ul>
                <div style={{padding:'5px', paddingLeft:'20px',border:'1px solid #ddd'}}>

                <b>Symotomps:</b>
                </div>
                {patientFormData?.symptomps?.map((symptom,index)=>{
                return (
                  <li key = {index} style={{padding:'5px 20px',margin:'5px',border:'1px solid #ddd'}}>
                  {symptom}
                </li>
                )
              })}
              </ul>
              <div>

              </div>
            </div>
    </>
  )
}

export default PatientFindings;
