// components
import Button from '@mui/material/Button'

import { useState, useEffect } from 'react';
import getFormMetaData from './data';
import FormFragment from './FormFragment';
import { sendData} from '@services/formSubmitService';
import WidgetsLoader from '../../WidgetsLoader';

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import addPatientSchema from '@schemas/addPatientSchema'
import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import { useNavigate } from 'react-router-dom';

const Form = ()=>{
  const { t} = useTranslation();
  const navigate = useNavigate()
  const [formId, setFormId] = useState(null)
  const [formMetaData, setFormMetaData] = useState()


  useEffect(()=>{
    getFormMetaData().then(data=>{
      // eslint-disable-next-line
      setFormId(data.id)
      setFormMetaData(data.schema)
  })
  },[]);

  const getFormObj = (formMetaData)=>{
    const obj = {}
    formMetaData?.forEach((page)=>{
      page.fields.forEach((field)=>{
        obj[field.fieldName] = ''
      })
    })
    return JSON.parse(window.sessionStorage.getItem("formData")) || obj
  }

  const [formData, setFormData] = useState( getFormObj(formMetaData) );
  const [errors,setErrors] = useState({})

  const {register, reset} = useForm({resolver:yupResolver(addPatientSchema)})


  useEffect(()=>{
    window.sessionStorage.setItem("formData",JSON.stringify(formData))
  },[formData])

  const [step,setStep] = useState(0)

  const nextStep =  ()=>{
    let errosOnPage  = {}

    const formStepMetaData = formMetaData[step].fields

    const validateArray = []
    formStepMetaData.forEach((field)=>{
          validateArray.push(new Promise(async(resolve,reject)=>{
          yup.reach(addPatientSchema, field.fieldName ).validate(formData[field.fieldName],{abortEarly:false}).then(()=>{
            resolve(true)
          }).catch(err=>{
            errosOnPage[field.fieldName] = {message: err.message}
            reject(err)
          })
      }))
    })
    Promise.all(validateArray).then(()=>{
      setErrors({})
      setStep(step+1)
    }).catch((errors)=>{
      setErrors(errosOnPage)
    })
  }
  const prevStep = ()=>{
    setStep(step-1)
  }


  const FormDisplay = (errors, register)=>{
    return <FormFragment formFieldData={formMetaData[step]} errors={errors} setFormData={setFormData} formData={formData} register={register} t={t}/>
  }

  const submitHandler  = async (e)=>{
    e.preventDefault();
    try{
      await addPatientSchema.validate(formData,{abortEarly:false});
      await sendData(formData,formId);
      setErrors({}  )
      reset();
      window.sessionStorage.setItem('formData',null)
      navigate('/admin/add-patient/success')
      // window.location.reload()
    }
    catch(err){
      const errorObj = {}
      err.inner.forEach(error => {
        errorObj[error.path] = error.message
      })
      setErrors(errorObj)
    }
  }
  if(!formMetaData){
    return <>
    <div style={{marginTop:'25%'}}>
    <WidgetsLoader/>
    </div>
    </>
  }
  return(
    <>

  <form onSubmit={submitHandler}>
    <div className="wrapper">
      <div>
          {FormDisplay(errors,register)}
          <div style={{display:'flex' ,justifyContent:'space-between'}}>
            <Button variant='contained' disabled={step === 0}  onClick={prevStep}>{t('Prev')}</Button>
            <Button variant='contained'  disabled={step === formMetaData.length-1} onClick={nextStep}>{t('Next')}</Button>
          </div>
      </div>
      <div style ={{display:'flex', justifyContent:'space-around', marginTop:'2em'}}>
      {step === formMetaData.length-1 ? <Button variant='contained' type= 'submit' size='large'>{t('Submit')}</Button>:'' }
      </div>
    </div>
  </form>
  </>)
}
export default Form
