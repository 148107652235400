import axios from "axios";
import { decodeToken } from "react-jwt";
const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_API_URL;

export const sendData = async (formData, formId) => {
  const token = localStorage.getItem("token");
  const formAdderId = decodeToken(token).id;
  const data = formData;

  const { phoneNumber, name, age, email, gender, address } = data;

  const password = Math.random().toString(36).slice(-8);
  const registerData = {
    phoneNumber,
    name,
    age,
    email,
    gender,
    address,
    password,
  };

  const response = await axios.post(
    `${baseUrl}patient/register`,
    registerData,
    {
      headers: {
        Authorization: `bearer ${token}`,
      },
    }
  );
  const userId = response.data.id;
  await axios.post(
    `${baseUrl}form-data`,
    {
      userId,
      formData,
      formId,
      formAdder: formAdderId,
    },
    {
      headers: {
        Authorization: `bearer ${token}`,
      },
    }
  );
  // window.sessionStorage.setItem('formData',null)
};

export const createForm = async (formMetaData) => {
  const token = localStorage.getItem("token");
  await axios.post(`${baseUrl}form`, formMetaData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  });
};
