import TimelineChart from "./TimelineChart";

const TimeLine = ({patient})=>{
  const data = patient.timeline

  return(
  <>
  <h1>
    Timeline
  </h1>
  <TimelineChart data={data}/>
  </>)
}
export default TimeLine;
export {TimelineChart}
