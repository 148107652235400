import TreatmentHistoryTable from "./TreatmentHistoryTable";

const TreatmentHistory = ({patient})=>{
  const treatmentHistory = patient.treatmentHistory
  return (
    <>
    <h1>
      Treatment History
    </h1>
    <TreatmentHistoryTable treatmentHistory={treatmentHistory}/>
    <div style={{display:'flex', justifyContent:'space-around', marginTop:'5px'}}>
      {!treatmentHistory?'No record found':''}
    </div>
    </>
  )
}

export default TreatmentHistory
export {TreatmentHistoryTable}
