import Page from '@layout/Page';
import Widget from '@components/Widget';
import { useParams } from 'react-router-dom';
import {Header} from '@components/Widget/style';
import  {NavWrapper} from './style';
import InfoNav from '@components/InfoNav'


import { getPatient } from './data';
import { useState, useEffect } from 'react';
import WidgetBody from '@components/Widget/WidgetBody';

import PatientData from '@components/PatientData';

const Patient = () => {
  const {id} = useParams()
  const [patient,setPatient] = useState(null)
  const [displayInfo,setDisplayInfo] = useState({value:'overview',label:'Overview'})

  useEffect(()=>{
    getPatient(id).then(data=>{
      setPatient(data)
    });
  },[id])

  if (!patient){
    return (
      <Page title="Patient">
      <Widget name ="a">
        <WidgetBody name="patient">

        </WidgetBody>
      </Widget>
    </Page>
    )
  }
  return (
        <Page title="Patient">
          <Widget name ="">
            <Header>
              <NavWrapper>
                <InfoNav state = {displayInfo} handler={setDisplayInfo}/>
              </NavWrapper>
            </Header>
            <WidgetBody name="patient">
              <div>
                <PatientData patient={patient} displayInfo={displayInfo}/>
              </div>
            </WidgetBody>
          </Widget>
        </Page>
    );
}

export default Patient;
